@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  font-family: "Cabin";
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Kenia&display=swap");

.nyhasina {
  font-family: "Kenia", sans-serif;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-hover:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.custom-drawer {
  display: none !important;
}

.custom-margin {
  margin-bottom: 0;
}

@media screen and (max-width: 900px) {
  .custom-responsive {
    flex-direction: column;
    margin-top: 2rem;
    height: 40rem;
  }
  .full-width-responsive {
    width: 100%;
    margin-top: 2rem;
  }
  .navbar-responsive {
    /* margin-top: -8rem; */
  }
  .custom-drawer {
    display: flex !important;
  }
}

.bg-black {
  height: 0 !important;
}
